<template>
  <nav>
    <ul>
      <li><router-link to="/" class="nav-link"><i class="bi bi-house-fill"></i></router-link></li>
      <li><router-link to="/photos" class="nav-link"><i class="bi bi-camera-fill"></i></router-link></li>
      <li><router-link to="/datatable" class="nav-link"><i class="bi bi-table"></i></router-link></li>
      <li><router-link to="/carte" class="nav-link"><i class="bi bi-geo-alt-fill"></i></router-link></li>
      <li><router-link to="/phone" class="nav-link"><i class="bi bi-phone"></i></router-link></li>
      <li><router-link to="/battery" class="nav-link"><i class="bi bi-battery-full"></i></router-link></li>
      <li><router-link to="/notification" class="nav-link"><i class="bi bi-bell"></i></router-link></li>
      <li><router-link to="/code" class="nav-link"><i class="bi bi-lock-fill"></i></router-link></li>
    </ul>
  </nav>
</template>
  
<style scoped>
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-link i {
  font-size: 24px; /* Taille par défaut des icônes */
}


.nav-link:hover {
  transform: scale(1.2);
}

</style>
