import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'; // Importez les fonctions nécessaires depuis Vue Router
import VueCookies from 'vue-cookies';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ './views/HomeView.vue')
  },
  {
    path: '/photos',
    component: () => import(/* webpackChunkName: "takepicture" */ './views/TakePictureView.vue')
  },
  {
    path: '/datatable',
    component: () => import(/* webpackChunkName: "datatable" */ './views/DataTableView.vue')
  },
  {
    path: '/carte',
    component: () => import(/* webpackChunkName: "datatable" */ './views/MapWithMarkerView.vue')
  },
  {
    path: '/phone',
    component: () => import(/* webpackChunkName: "datatable" */ './views/PhoneCallButtonView.vue')
  },
  {
    path: '/battery',
    component: () => import(/* webpackChunkName: "datatable" */ './views/BatteryLevelView.vue')
  },
  {
    path: '/notification',
    component: () => import(/* webpackChunkName: "datatable" */ './views/SendNotificationView.vue')
  },
  {
    path: '/code',
    component: () => import(/* webpackChunkName: "datatable" */ './views/SendCodeView.vue')
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes: routes, 
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!VueCookies.get('username');
  if (!isAuthenticated && to.path !== '/') {
    next('/');
  } else {
    next();
  }
});
function handleConnectivityChange() {
  if (navigator.onLine) {
    alert("Vous êtes connecté")
  } else {
    alert("Vous n'êtes pas connecté")
    // Afficher une alerte ici
    // Exemple avec une alerte JavaScript côté client
    // window.alert('Vous êtes hors ligne!');
  }
}

// Attacher les événements online et offline à la fenêtre du navigateur
window.addEventListener('online', handleConnectivityChange);
window.addEventListener('offline', handleConnectivityChange);

const app = createApp(App)
app.use(router)

// Enregistrement du service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker enregistré avec succès:', registration);
      })
      .catch(error => {
        console.error('Erreur lors de l\'enregistrement du Service Worker:', error);
      });
  });
} else {
  console.log("Votre navigateur ne prend pas en charge les service workers.");
}

app.mount('#app')