<template>
<div>
    <!-- Si l'utilisateur n'est pas connecté -->
    <div v-if="!isAuthenticated">
    <form @submit.prevent="connect">
        <input type="text" v-model="username" placeholder="Pseudo">
        <button type="submit">Se connecter</button>
    </form>
    </div>
    
    <!-- Si l'utilisateur est connecté -->
    <div v-else>
    <p>{{ username }} | <button @click="disconnect" class="disconnect-btn"><i class="bi bi-power"></i></button></p>
    
    </div>
</div>
</template> 

<script>
    import VueCookies from 'vue-cookies';

    export default {
    name: 'ConnexionLogin',
    data() {
        return {
        isAuthenticated: false,
        username: ''
        };
    },
    created() {
        // Vérifier si un cookie de session existe déjà
        this.isAuthenticated = !!VueCookies.get('username');
        if (this.isAuthenticated) {
        this.username = VueCookies.get('username');
        }
    },
    methods: {
        connect() {
        // Stocker le pseudo dans un cookie de session
        VueCookies.set('username', this.username);
        this.isAuthenticated = true;
        window.location.reload();
        },
        disconnect() {
        // Supprimer le cookie de session et déconnecter l'utilisateur
        VueCookies.remove('username');
        this.isAuthenticated = false;
        this.username = '';
        window.location.reload();
        }
    }
    };
</script>

<style>
p{
    margin: 0;
}



.disconnect-btn{
    color: red;
}

.disconnect-btn:hover {
  transform: scale(1.2);
}

.disconnect-btn {
  background: none;
  border: none;
}
</style>
