<template>
  <div class="MenuUser">
    <!-- Section de navigation -->
    <div class="navigation">
      <MenuNavigation/>
    </div>

    <!-- Section de connexion -->
    <div class="connexion">
      <div v-if="isAuthenticated">
        <ConnexionLogin />
      </div>
      <div v-else>
        <p><router-link to="/" class="nav-link">Se connecter</router-link></p>
      </div>
    </div>
  </div>
  <!-- Affichage des vues dynamiques -->
  <router-view/>
</template>

<script>
import MenuNavigation from './components/MenuNavigation.vue';
import ConnexionLogin from './components/ConnexionLogin.vue';
import VueCookies from 'vue-cookies';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: 'App',
  components: {
    MenuNavigation,
    ConnexionLogin
  },
  data() {
    return {
      isAuthenticated: false,
      username: ''
    };
  },
  created() {
    // Vérifiez si un cookie de session existe déjà
    this.isAuthenticated = !!VueCookies.get('username');
    if (this.isAuthenticated) {
      this.username = VueCookies.get('username');
    }
  }
};
</script>

<style>
/* Styles généraux de l'application */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* Styles de la barre de navigation et de la section de connexion */
.MenuUser {
  display: flex;
  flex-wrap: wrap; /* Permettre le passage à la ligne pour les petits écrans */
}

/* Styles de la section de navigation */
.navigation, .connexion {
  flex-grow: 1; /* Prendre autant d'espace que possible */
  min-width: 0; /* Permettre aux éléments de réduire leur taille si nécessaire */
  max-width: calc(50% - 10px); /* Limitez la largeur à 50% moins une marge */
  margin-right: 10px; /* Ajoutez une marge entre les éléments */
}

/* Media query pour les écrans de petite taille */
@media screen and (max-width: 768px) {
  .MenuUser {
    flex-direction: column; /* Affichez les éléments en colonne */
    align-items: center; /* Centrez les éléments horizontalement */
  }

  .navigation, .connexion {
    max-width: 100%; /* Utilisez la largeur maximale pour les petits écrans */
    margin-bottom: 20px; /* Ajoutez un espace entre les éléments */
  }

  .navigation a, .connexion a {
    display: block; /* Affichez les liens en bloc */
    padding: 10px; /* Ajoutez un espace autour des liens */
    border-bottom: 1px solid #ddd; /* Ajoutez un séparateur entre les liens */
  }

  .navigation a:last-child, .connexion a:last-child {
    border-bottom: none; /* Supprimez le séparateur du dernier lien */
  }
}
</style>
